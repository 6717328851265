import { ATTENDANCE_REGISTER_USER,RESET_ATTENDANCE_REGISTER,UPDATE_DATE_PER_USERID } from "../../constants/ActionTypes";

const INIT_STATE = {
    attendance_register: null,

};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ATTENDANCE_REGISTER_USER: {
            return {
                ...state,
                attendance_register: action.payload,
            }
        }

        case RESET_ATTENDANCE_REGISTER: {
            return {
                ...state,
                attendance_register: null,
            }
        }

        case UPDATE_DATE_PER_USERID: {
            const { userId, newValue } = action.payload || {};

            if (!state.attendance_register || !state.attendance_register[userId]) {
                console.warn(`User ${userId} not found or attendance_register is empty.`);
                return state;
            }

            // Update all dates for the specific userId
            const updatedAttendanceRegister = {
                ...state.attendance_register,
                [userId]: Object.keys(state.attendance_register[userId]).reduce((acc, date) => {
                    acc[date] = newValue;
                    return acc;
                }, {})
            };

            return {
                ...state,
                attendance_register: updatedAttendanceRegister,
            };
        }
        
        default:
            return state;
    }
}

