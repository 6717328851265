import React, { useState, useEffect } from 'react';
import { Input, Card, Button, Tag, Space, Typography, Tooltip, Divider } from 'antd';
import { SearchOutlined, CloseOutlined, LeftOutlined, RightOutlined, FolderOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const { Title, Text } = Typography;

const QuickSearch = () => {
    const [visible, setVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [hoveredItem, setHoveredItem] = useState(null);
    const itemsPerPage = 8;
    const history = useHistory();

    const routes = [
        { title: "Home", category: "General", path: "/" },
        { title: "Home", category: "General", path: "home" },
        { title: "Candidates", category: "Company", path: "/company/candidates" },
        { title: "Candidate Import Wizard", category: "Company", path: "/company/candidate-import-wizard" },
        { title: "New Candidate", category: "Company", path: "/company/new-candidate" },
        { title: "Candidate Profile", category: "Company", path: "/company/candidate/profile/:slug" },
        { title: "Document Types", category: "Company", path: "/company/document-types" },
        { title: "Documents", category: "Company", path: "/company/documents" },
        { title: "Company Announcements", category: "Company", path: "/company/announcements" },
        { title: "Employee Notification", category: "Company", path: "/company/notifications" },
        { title: "Company Holidays", category: "Company", path: "/company/company-holidays" },
        { title: "Add Announcement", category: "Company", path: "/company/announcement/add" },
        { title: "Modify Announcement", category: "Company", path: "/company/announcement/modify/:slug" },
        { title: "Events", category: "Company", path: "/company/events" },
        { title: "Event Reports", category: "Company", path: "/company/event-reports" },
        { title: "Appraisals", category: "Company", path: "/company/appraisals" },
        { title: "Appraisal Details", category: "Company", path: "/appraisals/details/:slug" },
        { title: "Appraisal Submissions", category: "Company", path: "/appraisals/:slug/:user_id" },
        { title: "Departments", category: "Company", path: "/company/departments" },
        { title: "User Groups", category: "Company", path: "/company/usergroups" },
        { title: "Job Roles", category: "Company", path: "/company/job-roles" },
        { title: "Letters", category: "Company", path: "/company/letters" },
        { title: "New Letter", category: "Company", path: "/company/letter/new" },
        { title: "Edit Letter", category: "Company", path: "/company/letter/edit/:slug" },
        { title: "Others Letter", category: "Company", path: "/company/letter/others" },
        { title: "Report Template", category: "Company", path: "/company/letter/report-template" },
        { title: "Pay Grades", category: "Company", path: "/company/pay-grades" },
        { title: "Onboarding Tasks", category: "Company", path: "/company/onboarding-tasks" },
        { title: "Pay Structures", category: "Company", path: "/company/pay-structures" },
        { title: "Rates", category: "Company", path: "/company/rates" },
        { title: "Branches", category: "Company", path: "/company/branches" },
        { title: "Employee Validations", category: "Employee", path: "/employee-validations/:id" },
        { title: "Company Validations", category: "Company", path: "/company/validations" },
        { title: "Help Desk", category: "Company", path: "/company/helpdesk" },
        { title: "Appraisal Documents", category: "Employee", path: "/employee/appraisal-documents" },
        { title: "Attendance", category: "Employee", path: "/employee/attendance" },
        { title: "Attendance Report", category: "Employee", path: "/employee/report_attendance" },
        { title: "Regularization Request", category: "Attendance", path: "/attendance/regularization" },
        { title: "Attendance Reports", category: "Attendance", path: "/attendance/reports" },
        { title: "Employee Center", category: "Employee", path: "/employee/employee-center" },
        { title: "Employee Verifications", category: "Employee", path: "/employee/employee-verifications" },
        { title: "Employee Rates", category: "Employee", path: "/employee/employee-rates" },
        { title: "Employee Files", category: "Employee", path: "/employee/employee-files" },
        { title: "Salary Advances", category: "Employee", path: "/employee/salary-advances" },
        { title: "Covid Vaccinations", category: "Employee", path: "/employee/covid-vaccinations" },
        { title: "Supervisors", category: "Employee", path: "/employee/supervisors" },
        { title: "Document Status", category: "Employee", path: "/employee/document-status" },
        { title: "New Employee", category: "Employee", path: "/employee/new-employee" },
        { title: "Modify Employee", category: "Employee", path: "/employee/modify/:slug" },
        { title: "Employee Import Wizard", category: "Employee", path: "/employee/employee-import-wizard" },
        { title: "Employee Variations", category: "Employee", path: "/employee/employee-variations" },
        { title: "Variation Wizard", category: "Employee", path: "/employee/variation-wizard" },
        { title: "Training Schedules", category: "Employee", path: "/employee/training-schedules" },
        { title: "Employee Exits", category: "Employee", path: "/employee/exits" },
        { title: "Confidential Notes", category: "Issues", path: "/issues/confidential-notes" },
        { title: "Cases", category: "Issues", path: "/issues/cases" },
        { title: "Blacklist", category: "Issues", path: "/issues/blacklist" },
        { title: "Employee Queries", category: "Issues", path: "/issues/employee-queries" },
        { title: "Add Employee Query", category: "Issues", path: "/issues/query/add" },
        { title: "Modify Employee Query", category: "Issues", path: "/issues/modify/:slug" },
        { title: "View Employee Query", category: "Issues", path: "/issues/view/:slug" },
        { title: "Profile Verifications", category: "Employee", path: "/employee/profile-verifications" },
        { title: "Leave", category: "Leave Tracker", path: "/leave-tracker/leave" },
        { title: "Leave Applications", category: "Leave Tracker", path: "/leave-tracker/leave-applications" },
        { title: "Leave Applications Others", category: "Leave Tracker", path: "/leave-tracker/leave-applications-others" },
        { title: "Leave Resumption", category: "Leave Tracker", path: "/leave-tracker/leave-resumption" },
        { title: "Employee Exclusions", category: "Payroll", path: "/payroll/employee-exclusions/:payrollId" },
        { title: "Deleted Earnings", category: "Payroll", path: "/payroll/deleted-earnings/:payrollId" },
        { title: "Payroll Center", category: "Payroll", path: "/payroll/payroll-center" },
        { title: "Payroll Register", category: "Payroll", path: "/payroll/payroll-register/:id" },
        { title: "Polls", category: "Voting", path: "/voting/polls" },
        { title: "Voters Group", category: "Voting", path: "/voting/voters-group" },
        { title: "Votes Results", category: "Voting", path: "/voting/votes" },
    ];

    const categories = ['All', ...new Set(routes.map(route => route.category))];

    const filteredRoutes = routes.filter(route => {
        const matchesSearch = route.title.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesCategory = selectedCategory === 'All' || route.category === selectedCategory;
        return matchesSearch && matchesCategory;
    });

    const displayedRoutes = filteredRoutes.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );

    const totalPages = Math.ceil(filteredRoutes.length / itemsPerPage);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(0);
    };

    const handleNavigate = (path) => {
        //history.push(path);
       // window.location.href = path; 
        //window.open(path, '_self'); 
        window.location.replace(path); 
        setVisible(false);
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if ((e.ctrlKey || e.metaKey) && e.key === 'k') {
                e.preventDefault();
                setVisible(true);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    return (
        <div style={{ position: 'fixed', bottom: '20px', right: '25px', zIndex: 9999, bottom: '150px' }}>
            {visible ? (
                <Card
                    style={{
                        width: 700,
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        maxHeight: '80vh',
                        display: 'flex',
                        flexDirection: 'column',
                        boxShadow: '0 4px 24px rgba(0, 0, 0, 0.15)',
                        borderRadius: '8px',
                        animation: 'fadeIn 0.2s ease-in-out'
                    }}
                    bodyStyle={{ padding: '16px', height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                    <style>
                        {`
                            @keyframes fadeIn {
                                from { opacity: 0; transform: translate(-50%, -48%); }
                                to { opacity: 1; transform: translate(-50%, -50%); }
                            }
                            .category-tag {
                                cursor: pointer;
                                transition: all 0.3s;
                            }
                            .category-tag:hover {
                                opacity: 0.8;
                            }
                            .route-item {
                                padding: 12px;
                                border-radius: 6px;
                                transition: all 0.2s;
                                cursor: pointer;
                                margin-bottom: 8px;
                            }
                            .route-item:hover {
                                background-color: #f5f5f5;
                                transform: translateX(4px);
                            }
                        `}
                    </style>
                    
                    <div style={{ position: 'relative', marginBottom: '16px' }}>
                        <Input
                            // prefix={<SearchOutlined style={{ color: '#bfbfbf' }} />}
                            prefix={<SearchOutlined style={{ color: '#97aec3' }} />}
                            suffix={
                                <CloseOutlined
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setVisible(false)}
                                />
                            }
                            placeholder="Search pages... (Press Ctrl + K)"
                            value={searchTerm}
                            onChange={handleSearch}
                            autoFocus
                            size="large"
                            style={{ borderRadius: '6px' }}
                        />
                    </div>

                    <Space style={{ marginBottom: '16px', flexWrap: 'wrap' }}>
                        {categories.map(category => (
                            <Tag
                                key={category}
                                color={selectedCategory === category ? '#1890ff' : undefined}
                                className="category-tag"
                                onClick={() => setSelectedCategory(category)}
                                style={{ padding: '4px 12px', fontSize: '14px' }}
                            >
                                <FolderOutlined /> {category}
                            </Tag>
                        ))}
                    </Space>

                    <div style={{ 
                        flex: 1, 
                        overflowY: 'auto',
                        marginBottom: '16px',
                        paddingRight: '8px'
                    }}>
                        {displayedRoutes.map((route, index) => (
                            <div
                                key={route.path}
                                className="route-item"
                                onMouseEnter={() => setHoveredItem(index)}
                                onMouseLeave={() => setHoveredItem(null)}
                                onClick={() => handleNavigate(route.path)}
                            >
                                <Title level={5} style={{ margin: 0, color: hoveredItem === index ? '#1890ff' : undefined }}>
                                    {route.title}
                                </Title>
                                <Text type="secondary" style={{ fontSize: '12px' }}>
                                    {route.category}
                                </Text>
                            </div>
                        ))}
                    </div>

                    {totalPages > 1 && (
                        <>
                            <Divider style={{ margin: '12px 0' }} />
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Button
                                    type="text"
                                    icon={<LeftOutlined />}
                                    onClick={() => setCurrentPage(prev => Math.max(0, prev - 1))}
                                    disabled={currentPage === 0}
                                >
                                    Previous
                                </Button>
                                <Text type="secondary">
                                    Page {currentPage + 1} of {totalPages}
                                </Text>
                                <Button
                                    type="text"
                                    icon={<RightOutlined />}
                                    onClick={() => setCurrentPage(prev => Math.min(totalPages - 1, prev + 1))}
                                    disabled={currentPage === totalPages - 1}
                                >
                                    Next
                                </Button>
                            </div>
                        </>
                    )}
                </Card>
            ) : (
                <Tooltip title="Search (Ctrl + K)">
                    <Button
                        type="default"
                        shape="circle"
                        icon={<SearchOutlined />}
                        size="large"
                        onClick={() => setVisible(true)}
                        style={{
                            width: '48px',
                            height: '48px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                            transition: 'all 0.3s',
                        }}
                        className="search-button"
                    />
                </Tooltip>
            )}
        </div>
    );
};

export default QuickSearch;