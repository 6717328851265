import { Typography } from "antd"

const { Title, Paragraph } = Typography

export function TutorialContent({ topic, contentType, walkthroughContent }) {
  return (
    <div style={{ padding: "24px", overflowY: "auto", height: "100%" }}>
      <Title level={3}>
        {topic} - {contentType.charAt(0).toUpperCase() + contentType.slice(1)}
      </Title>
      {contentType === "video" && (
        <div
          style={{
            aspectRatio: "16/9",
            backgroundColor: "#f0f0f0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "16px",
          }}
        >
          <span>Video placeholder for {topic}</span>
        </div>
      )}
      {contentType === "walkthrough" && (
        <div>
          {/* <ol style={{ paddingLeft: "24px" }}>
            <li>Step 1 for {topic}</li>
            <li>Step 2 for {topic}</li>
            <li>Step 3 for {topic}</li>
            <li>Step 4 for {topic}</li>
            <li>Step 5 for {topic}</li>
          </ol> */}
          <div>
            {walkthroughContent && (
              <div dangerouslySetInnerHTML={{ __html: walkthroughContent }} />
            )}
          </div>
        </div>
      )}
      {contentType === "faq" && (
        <div>
          <Paragraph>
            <Title level={4}>Question 1 about {topic}?</Title>
            <p>Answer to question 1 about {topic}.</p>
          </Paragraph>
          <Paragraph>
            <Title level={4}>Question 2 about {topic}?</Title>
            <p>Answer to question 2 about {topic}.</p>
          </Paragraph>
          <Paragraph>
            <Title level={4}>Question 3 about {topic}?</Title>
            <p>Answer to question 3 about {topic}.</p>
          </Paragraph>
        </div>
      )}
    </div>
  )
}

