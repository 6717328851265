import { useState, useEffect } from "react"
import { Button , Tooltip} from "antd"
import { QuestionCircleOutlined, BulbOutlined } from "@ant-design/icons"
import { TutorialModal } from "./TutorialModal"

export function Tutorial() {
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    const handleKeyDown = (e) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'l') {
            e.preventDefault();
            setIsModalOpen(true);
        }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
}, []);

  return (
    <>
    <Tooltip title="Knowledge Centre (Ctrl + L)">

      <Button
        icon={<BulbOutlined />}
        shape="circle"
        type="default"
        style={{ position: 'fixed', right: 24, bottom: 94,  width: '48px',
            height: '48px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
            transition: 'all 0.3s', }}
        // style={{
        //     width: '48px',
        //     height: '48px',
        //     display: 'flex',
        //     alignItems: 'center',
        //     justifyContent: 'center',
        //     boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
        //     transition: 'all 0.3s',
        // }}
        onClick={() => setIsModalOpen(true)}
      />
      </Tooltip>
      <TutorialModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  )
}
