import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "../util/asyncComponent";

const App = ({match}) => (
    <div className="">
        <Switch>
            <Route path={`${match.url}/`} component={asyncComponent(() => import('./Home'))}/>
            <Route path={`${match.url}home`} component={asyncComponent(() => import('./Home'))}/>


            <Route path={`${match.url}company/candidates`}
                   component={asyncComponent(() => import('./Candidates'))}/>
            <Route path={`${match.url}company/candidate-import-wizard`}
                   component={asyncComponent(() => import('./CandidateSetup/CandidateImportWizard'))}/>
            <Route path={`${match.url}company/new-candidate`}
                   component={asyncComponent(() => import('./CandidateSetup/NewCandidate'))}/>

            <Route path={`${match.url}company/candidate/profile/:slug`}
                   component={asyncComponent(() => import('./CandidateProfile'))}/>

            <Route path={`${match.url}company/document-types`}
                   component={asyncComponent(() => import('./DocumentTypes'))}/>
            <Route path={`${match.url}company/document-types`}
                   component={asyncComponent(() => import('./DocumentTypes'))}/>

            <Route path={`${match.url}company/documents`}
                   component={asyncComponent(() => import('./Documents'))}/>

            <Route path={`${match.url}company/announcements`}
                   component={asyncComponent(() => import('./CompanyAnnouncements'))}/>

            <Route path={`${match.url}company/notifications`}
                   component={asyncComponent(() => import('./EmployeeNotification'))}/>

            <Route path={`${match.url}company/company-holidays`}
                   component={asyncComponent(() => import('./CompanyHoliday'))}/>

            <Route path={`${match.url}company/announcement/add`}
                   component={asyncComponent(() => import('./CompanyAnnouncements/AddAnnouncement'))}/>

            <Route path={`${match.url}company/announcement/modify/:slug`}
                   component={asyncComponent(() => import('./CompanyAnnouncements/ModifyAnnouncement'))}/>

            <Route path={`${match.url}company/events`}
                   component={asyncComponent(() => import('./Events'))}/>
            <Route path={`${match.url}company/event-reports`}
                   component={asyncComponent(() => import('./Events/EventAnalytics'))}/>
              
             <Route path={`${match.url}company/appraisals`}
                   component={asyncComponent(() => import('./Appraisals'))}/>
             <Route path={`${match.url}appraisals/details/:slug`}
                   component={asyncComponent(() => import('./AppraisalDetails'))}/>
              <Route path={`${match.url}appraisals/:slug/:user_id`}
                   component={asyncComponent(() => import('./AppraisalDetails/AppraisalSubmissions'))}/>
             
            <Route path={`${match.url}company/departments`}
                   component={asyncComponent(() => import('./Departments'))}/>
            <Route path={`${match.url}company/usergroups`}
                   component={asyncComponent(() => import('./UserGroups'))}/>
            <Route path={`${match.url}company/job-roles`}
                   component={asyncComponent(() => import('./JobRoles'))}/>
            <Route path={`${match.url}company/letters`}
                   component={asyncComponent(() => import('./Letters'))}/>
            <Route path={`${match.url}company/letter/new`}
                   component={asyncComponent(() => import('./Letters/NewLetter'))}/>
            <Route path={`${match.url}company/letter/edit/:slug`}
                   component={asyncComponent(() => import('./Letters/UpdateLetter'))}/>
            <Route path={`${match.url}company/letter/others`}
                   component={asyncComponent(() => import('./Letters/NewTemplateOthers'))}/>
            <Route path={`${match.url}company/letter/report-template`}
                   component={asyncComponent(() => import('./Letters/NewReportTemplate'))}/>
            
            <Route path={`${match.url}company/pay-grades`}
                   component={asyncComponent(() => import('./OnBoardingTasks'))}/>
            <Route path={`${match.url}company/onboarding-tasks`}
                   component={asyncComponent(() => import('./OnBoardingTasks'))}/>
            <Route path={`${match.url}company/pay-structures`}
                   component={asyncComponent(() => import('./PayStructure'))}/>
            <Route path={`${match.url}company/rates`}
                   component={asyncComponent(() => import('./CompanyRates'))}/>
            <Route path={`${match.url}company/branches`}
                   component={asyncComponent(() => import('./Branches'))}/>

            <Route path={`${match.url}employee-validations/:id`}
                   component={asyncComponent(() => import('./EmployeeValidations'))}/>

            <Route path={`${match.url}employee-validation-approval`}
                   component={asyncComponent(() => import('./EmployeeSalaryApproval'))}/>

            <Route path={`${match.url}company/validations`}
                   component={asyncComponent(() => import('./CompanyValidations'))}/>

            <Route path={`${match.url}company/helpdesk`}
                   component={asyncComponent(() => import('./HelpDesk'))}/>

            <Route path={`${match.url}employee/appraisal-documents`}
                   component={asyncComponent(() => import('./AppraisalDocuments'))}/>
            <Route path={`${match.url}employee/attendance`}
                   component={asyncComponent(() => import('./Attendance'))}/>

              <Route path={`${match.url}employee/report_attendance`}
              component={asyncComponent(() => import('./AttendanceReport'))}/>

            <Route path={`${match.url}attendance/regularization`}
                   component={asyncComponent(() => import('./RegularizationRequest'))}/>

            <Route path={`${match.url}attendance/reports`}
                   component={asyncComponent(() => import('./AttendanceReport'))}/>
                   
              <Route path={`${match.url}attendance/totalhours`}
              component={asyncComponent(() => import('./AttendanceHours'))}/>

              <Route path={`${match.url}attendance/daily_attendance_status`}
                   component={asyncComponent(() => import('./Attendance/DailyAttendance'))}/>

            <Route path={`${match.url}employee/employee-center`}
                   component={asyncComponent(() => import('./EmployeeCenter'))}/>
            <Route path={`${match.url}employee/employee-verifications`}
                   component={asyncComponent(() => import('./EmployeeVerifications'))}/>
            <Route path={`${match.url}employee/employee-rates`}
                   component={asyncComponent(() => import('./EmployeePayRates'))}/>

            <Route path={`${match.url}employee/employee-files`}
                   component={asyncComponent(() => import('./EmployeeFiles'))}/>

            <Route path={`${match.url}employee/salary-advances`}
                   component={asyncComponent(() => import('./SalaryAdvances'))}/>
            <Route path={`${match.url}employee/covid-vaccinations`}
                   component={asyncComponent(() => import('./CovidVaccinations'))}/>

            <Route path={`${match.url}employee/supervisors`}
                   component={asyncComponent(() => import('./Supervisors'))}/>

            <Route path={`${match.url}supervisor/employeevalidation`}
                   component={asyncComponent(() => import('./Supervisor_EmployeeValidation'))}/>

       
            <Route path={`${match.url}employeevalidation/reports`}
                     component={asyncComponent(() => import('./EmployeeValidationReport'))}/>

           <Route path={`${match.url}employeevalidation/report/:userid`}
                     component={asyncComponent(() => import('./ViewEmployeesReports'))}/>

            <Route path={`${match.url}employee/document-status`}
                   component={asyncComponent(() => import('./DocumentStatus'))}/>

            <Route path={`${match.url}employee/new-employee`}
                   component={asyncComponent(() => import('./EmployeeSetup/NewEmployee'))}/>
            <Route path={`${match.url}employee/modify/:slug`}
                   component={asyncComponent(() => import('./EmployeeModification'))}/>

            <Route path={`${match.url}employee/employee-import-wizard`}
                   component={asyncComponent(() => import('./EmployeeSetup/EmployeeImportWizard'))}/>
            <Route path={`${match.url}employee/employee-variations`}
                   component={asyncComponent(() => import('./EmployeeVariations'))}/>
            <Route path={`${match.url}employee/variation-wizard`}
                   component={asyncComponent(() => import('./EmployeeVariationWizard'))}/>

            <Route path={`${match.url}employee/training-schedules`}
                   component={asyncComponent(() => import('./TrainingSchedules'))}/>

            <Route path={`${match.url}employee/exits`}
                   component={asyncComponent(() => import('./EmployeeExits'))}/>


            <Route path={`${match.url}issues/confidential-notes`}
                   component={asyncComponent(() => import('./ConfidentialNotes'))}/>

            <Route path={`${match.url}issues/cases`}
                   component={asyncComponent(() => import('./CompanyCases'))}/>

            <Route path={`${match.url}issues/blacklist`}
                   component={asyncComponent(() => import('./EmployeeBlackList'))}/>

            <Route path={`${match.url}issues/employee-queries`}
                   component={asyncComponent(() => import('./EmployeeQueries'))}/>
            <Route path={`${match.url}issues/query/add`}
                   component={asyncComponent(() => import('./EmployeeQueries/AddEmployeeQuery'))}/>
            <Route path={`${match.url}issues/modify/:slug`}
                   component={asyncComponent(() => import('./EmployeeQueries/ModifyEmployeeQuery'))}/>
            <Route path={`${match.url}issues/view/:slug`}
                   component={asyncComponent(() => import('./EmployeeQueries/ModifyEmployeeQuery'))}/>
            <Route path={`${match.url}employee/profile-verifications`}
                   component={asyncComponent(() => import('./ProfileVerifications'))}/>
            <Route path={`${match.url}leave-tracker/leave`}
                   component={asyncComponent(() => import('./Leave'))}/>
            <Route path={`${match.url}leave-tracker/leave-applications`}
                   component={asyncComponent(() => import('./LeaveApplications'))}/>

            <Route path={`${match.url}leave-tracker/leave-applications-others`}
                   component={asyncComponent(() => import('./LeaveApplicationOthers'))}/>
            <Route path={`${match.url}leave-tracker/leave-resumption`}
                   component={asyncComponent(() => import('./LeaveResumption'))}/>
            <Route path={`${match.url}payroll/employee-exclusions/:payrollId`}
                   component={asyncComponent(() => import('./EmployeeExclusions'))}/>
            <Route path={`${match.url}payroll/deleted-earnings/:payrollId`}
                   component={asyncComponent(() => import('./DeletedEarnings'))}/>
            <Route path={`${match.url}payroll/payroll-center`}
                   component={asyncComponent(() => import('./PayrollCenter'))}/>
            <Route path={`${match.url}payroll/payroll-register/:id`}
                   component={asyncComponent(() => import('./PayrollCenter/PayrollRegister'))}/>
       
            <Route path={`${match.url}voting/polls`}
                   component={asyncComponent(() => import('./Polls'))}/>
            <Route path={`${match.url}voting/voters-group`}
                   component={asyncComponent(() => import('./VotersGroup'))}/>
            <Route path={`${match.url}voting/votes`}
                   component={asyncComponent(() => import('./VotesResults'))}/>


        </Switch>
    </div>
);

export default App;
