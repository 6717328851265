"use client"

import { useState } from "react"
import { Input, Menu } from "antd"
import { SearchOutlined } from "@ant-design/icons"

const topics = [
  "Dashboard Overview",
  "Data Visualization",
  "User Management",
  "Settings Configuration",
  "Report Generation",
  "Analytics Tools",
  "Performance Metrics",
  "Security Features",
  "Customization Options",
  "Integration Guide",
]

export function TopicSelector({ onSelectTopic }) {
  const [searchQuery, setSearchQuery] = useState("")

  const filteredTopics = topics.filter((topic) => topic.toLowerCase().includes(searchQuery.toLowerCase()))

  return (
    <div style={{ width: 250, borderRight: "1px solid #f0f0f0", display: "flex", flexDirection: "column" }}>
      <div style={{ padding: "16px" }}>
        <Input
          placeholder="Search topics"
          prefix={<SearchOutlined />}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <Menu
        mode="inline"
        style={{ flex: 1, overflowY: "auto" }}
        items={filteredTopics.map((topic) => ({ key: topic, label: topic }))}
        onClick={({ key }) => onSelectTopic(key)}
      />
      {filteredTopics.length === 0 && (
        <div style={{ padding: "16px", textAlign: "center", color: "#999" }}>No topics found</div>
      )}
    </div>
  )
}

