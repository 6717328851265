import {Modal, Spin, message,Button, Tooltip, Popconfirm, Alert} from "antd";
import {ClockCircleOutlined, LoadingOutlined, } from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {fetchRecords} from "../../appRedux/actions";
import {GET_EMPLOYEE_TIME, TOGGLE_CHECK_IN} from "../../constants/ServerUrl";
import ErrorMessage from "../ErrorMessage";
import locationIcon from '../../assets/images/location.gif'
import trackIcon from '../../assets/images/location-access.gif'
import '../../assets/zoom.css'



const CheckIn=()=>{
    const [loading, isLoading] = useState(false);
    const [active, isActive] = useState(true);
    const [time, setTime] = useState("00:00:00");
    const [checkIn, isCheckIn] = useState(false);
    const [signIn, isSignedIn] = useState(false);
    const [mount, setMount] = useState(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [timeHandle, setTimeHandle] = useState(0);
    const [myIp, setMyIp] = useState("");
    const [error, setError] = useState(null);
    const [locationPermission, setLocationPermission] = useState('default'); 
    const [location, setLocation] = useState({ latitude: null, longitude: null });
    useEffect(() => {   

        let timeOutId = setTimeout(() => {
            mountedRequests();
        }, 1200);

        const checkTime = () => {
            const now = new Date();
            const hours = now.getHours();
            const minutes = now.getMinutes();
            const hour = '15';
            const minute = '01';
    
            if (hours === Number(hour) && minutes === Number(minute)) {
                getEmployeeTime()
            }
        };

        checkTime();
        const intervalId = setInterval(checkTime, 6000000); 

        console.log("mounting  component with id " + timeOutId);
        return () => {
            console.log("Unmounting component with id " + timeOutId);
            clearTimeout(timeOutId);
            clearInterval(intervalId);
            confirmLocation()
            setMount(false);

        }
    }, []);
    const getLocation = () => {

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setLocation({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              });
              message.success("Access Successfully Granted. You can now check in/check out");
            },
            (error) => {
              //message.error(error.message);
            }
          );
        } else {
          message.error("Geolocation is not supported by this browser.");
        }
    };

    const  handleGeoLogin = () => {
        isLoading(true)
        if (location.latitude == null) {
            // getLocation();
            // wait(5)
            confirmLocation();
        }
       
        if (location.latitude != null) {
            toggleCheckIn()
        }
        isLoading(false)
        setShowTooltip(false)
        if (locationPermission == 'denied') {
            message.error('Please enable geolocation from your browser before you can proceed')
            setShowTooltip(true)
        } 
        
    }
    const  wait = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    const mountedRequests = async () => {
        if (mount) {

            isActive(true)
            getEmployeeTime();
            getMyFrontendIp();
            
            if ('geolocation' in navigator) {
                navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                  if (result.state === 'granted') {
                    setLocationPermission('granted');
                    console.log('location is granted')
                    confirmLocation()
                  } else if (result.state === 'denied') {
                    setLocationPermission('denied');
                    console.log('location is denied')
                  } else if (result.state === 'prompt') {
                    //requestLocationAccess();
                    console.log('location is prompt')
                  }
                });
              } else {
                console.error('Geolocation is not supported by this browser.');
              }

            
            if (locationPermission == 'granted') {
                await confirmLocation()
            }
            isActive(false)
        }
    }
    const getEmployeeTime = () => {
        fetchRecords(GET_EMPLOYEE_TIME).then(res => {
            if (res.success) {
                let checkedIn = res.is_check_in;
                isCheckIn(checkedIn);
                let timeUsed = res.time_used;
                if (checkedIn) {
                    // setTime(timeUsed);
                    startTimer(timeUsed);
                } else {
                    stopTimer(timeUsed);
                }

                isSignedIn(true)

            }

        }).catch(err => {

            console.log(err);
        });
    }

    const toggleCheckIn = () => {
        if (loading) {
            return;
        }

        if (location.longitude) {
            setLocationPermission('granted')
        }

        isLoading(true);
        isSignedIn(false)
        const add = '&longitude='+location.longitude+'&latitude='+location.latitude
        fetchRecords(TOGGLE_CHECK_IN+'?frontendIp='+myIp+add).then(res => {
            if (res.success) {
                let checkedIn = res.is_check_in;
                isCheckIn(checkedIn);
                isSignedIn(true)
                let timeUsed = res.time_used;
                if (checkedIn) {
                    // setTime(timeUsed);
                    startTimer(timeUsed);
                } else {
                    stopTimer(timeUsed);
                }
                isLoading(true);
                setShowTooltip(false)

            } else {
                message.warn(res.error);
                confirmLocation()
                if (locationPermission == 'denied') {
                    setShowTooltip(true)
                }
               
            }
            isLoading(false);
        }).catch(err => {
            isLoading(false);
            if (locationPermission == 'denied') {
                setShowTooltip(true)
            } else {
                message.error("Problem checking you in. Please check your connection and retry");
            }
        });

    }

    const confirmLocation = async () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition (
                (position)  =>  {
            
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                    // message.success("Access Successfully Granted. You can now check in/check out");
                    console.log('Geolocation set ')
                },
                (error) => {
                    //message.error(error.message);
                }
            );
        }
         

         return;
    }

    const silentGeoLocation = async () => {
        if (signIn) {
            return
        }
        if (locationPermission == 'denied' ) {
            setShowTooltip(true)
        } else {

            if (navigator.geolocation) {
                 navigator.geolocation.getCurrentPosition(
                  (position) => {
                
                    setLocation({
                      latitude: position.coords.latitude,
                      longitude: position.coords.longitude,
                    });
                    // message.success("Access Successfully Granted. You can now check in/check out");
                    console.log('Geolocation set ')
                  },
                  (error) => {
                    message.error(error.message);
                  }
                );
              } else {
                message.error("Geolocation is not supported by this browser.");
              }
              return
        }
    }

    const  getMyFrontendIp = async () => {
        try {
            const response = await fetch('https://ipapi.co/json');
            if (!response.ok) {
                throw new Error('Failed to fetch IP address');
            }
            const data = await response.json();
            console.log(data.ip);
            setMyIp(data.ip);
        } catch (error) {
            console.error('Error fetching IP address:', error);
            return null;
        }
    
    }

    const startTimer = (timeUsed = time) => {

        let handler = setInterval(() => {
            timeUsed = countTheTime(timeUsed);
            setTime(timeUsed);
        }, 1000);
        setTimeHandle(handler);
        return timeUsed;

    }

    const countTheTime = (timeUsed) => {
        let theTime = timeUsed.split(":");
        let toDay = new Date();
        let hours = parseInt(theTime[0]) * 60 * 60;
        let minutes = parseInt(theTime[1]) * 60;
        let seconds = (hours + minutes + parseInt(theTime[2])) + 1;


        let newTime = new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate(), 0, 0, seconds);

        let hour = addLeadingZero(newTime.getHours());
        let minute = addLeadingZero(newTime.getMinutes());
        let second = addLeadingZero(newTime.getSeconds());
        return hour + ":" + minute + ":" + second;
    }

    const addLeadingZero = (theTime) => {
        if (theTime < 10) {
            theTime = "0" + theTime;
        }
        return theTime;
    }
    const stopTimer = (timeUsed) => {
        clearInterval(timeHandle);
        setTimeHandle(0);
        setTime(timeUsed);

    }
 return(
    <div>

   

        {active && (
            <>Loading....</>
        )}

        {
            !active && (
                <Button size="medium" type="default" onClick={toggleCheckIn}
                    className={`px-4 text-base rounded ${checkIn ? 'ant-btn-success ant-btn-2' : ''}  `}><span
                    className="text-sm mr-3">{checkIn ? "CHECK-OUT" : "CHECK-IN"}</span> {loading ? (
                    <LoadingOutlined className="align-baseline mr-4 text-sm"/>) : (<ClockCircleOutlined
                    className="align-baseline text-sm mr-4"/>)} | <span
                    className="px-4 text-sm">{time} Hrs</span>
                   
                </Button>
                
            )
            
        }

        {
            showTooltip && (
                <Modal
                    width={600}
                    title={<div className="text-center ">ALTERNATIVE SIGN IN</div>} centered
                    open={showTooltip}
                    onCancel={() => {
                        setShowTooltip(false);
                    }}
                    okText="Confirm"
                    cancelText="Cancel"
                    forceRender={true}
                    onOk={handleGeoLogin}
                    className="bg-blue-300"
                >
                    <div  className="bg-blue-300">
                        <Spin spinning={loading} tip="Processing...">
                            <ErrorMessage error={error}/>
                            <div className="text-center center  justify-center" >
                                <img src={locationIcon} className="mx-auto"  />
                            </div>
                            
                            <Alert className="mt-4 mb-2" message=" PEMS needs access to your Geolocation to enable alternative Sign In. Kindly give accees to your location from the browser in order to proceed. After that, you may reload your browser and retry"></Alert>
                            {/* <img src={trackIcon} width="500" className="image-container mt-4" /> */}
                            <div className="image-container mt-4">
                                <img src={trackIcon} alt="Track Icon" width="500" className="zoomable-image" />
                            </div>
                            {/* <div
                                className="image-container mt-4"
                                style={{ 
                                    backgroundImage: `url(${trackIcon})`,
                                    width: '100%',  // Adjust the width as needed
                                    height: '400px', // Set a height for the div
                                    backgroundSize: 'cover', // Adjust to fit the background
                                    backgroundPosition: 'center', // Center the image
                                    backgroundRepeat: 'no-repeat' // Prevent the background image from repeating
                                }}
                            ></div> */}
                            {/* <h2 className="mt-4">Allow Access to your GPS Location to Sign In</h2> */}
                        </Spin>
                    </div>
                    
                </Modal>
            )
        }
        
                
            
        
        

    </div>

    
  
 );
}

export default CheckIn;