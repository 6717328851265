"use client"

import { useState } from "react"
import { Modal, Tabs, Button } from "antd"
import { TopicSelector } from "./TopicSelector"
import { TutorialContent } from "./TutorialContent"
import { ExpandAltOutlined, CompressOutlined } from '@ant-design/icons'
const embeedContent = '<iframe src="https://scribehow.com/embed/How_to_Grant_Users_Access_to_a_company_in_PEMS__m0pYI6_wSsOo0gvFZVJerA" width="100%" height="640" allowfullscreen frameborder="0"></iframe>'
const htmlcontent = 
`<div>
<div style="text-align:center"><div><h2>How to create and manage leads </h2></div></div>

<div style="position:relative;padding-bottom:56.25%;">
<iframe style="width:100%;height:100%;position:absolute;left:0px;top:0px" 
src="https://embed.app.guidde.com/playbooks/iQDcvqJNo12vY1C5fjXFGT"
title="How to create and manage leads "
frameborder="0"
referrerpolicy="unsafe-url"
allowfullscreen="true"
allow="clipboard-write"
sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-forms allow-same-origin allow-presentation"></iframe>
</div>

<div style="margin-top:16px; margin-bottom:16px" >
  Empower your team with the knowledge to efficiently create and manage leads using Phillipsoutsourcing
  
</div> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">
            Go to <a href="https://dev-home.phillipsoutsourcing.net" target="_blank">dev-home.phillipsoutsourcing.net</a>
        </h3> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">1. Introduction</h3> <div style="margin-top:16px; margin-bottom:16px" >
   This guide will walk you through a series of steps to streamline the process of adding new leads, creating deals, and saving important notes.
  
</div> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">2. Click "Manage your customers with ease"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Access customer management effortlessly.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2Fvo2iXn4ibMcy2LsS5vWahB_doc.png?alt=media&amp;token=4d1e151b-2117-4cec-932d-be4551d04698" alt="Click 'Manage your customers with ease'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">3. Switch to "dev-crm.phillipsoutsourcing.net"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Navigate to the development CRM platform.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2F2r6gWZnJUioTGtUvpuTSqy_doc.png?alt=media&amp;token=923b0223-1c71-4d33-9593-f569f57c3bc5" alt="Switch to 'dev-crm.phillipsoutsourcing.net'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">4. Click "Leads"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Access the leads section.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2F8vS94zQEx7v6Uo9eZdbGrE_doc.png?alt=media&amp;token=4d854ff2-b891-4852-89f5-ceb524e95560" alt="Click 'Leads'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">5. Click "Add Lead"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Start adding a new lead.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FfW5AVEfibyUGGmUB9xG74K_doc.png?alt=media&amp;token=8a5bfc5d-7368-4bde-9d66-9a1ad3a96607" alt="Click 'Add Lead'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">6. Click "Enter company name"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Enter the company name field.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FpMsqd9DdvHuXhopvEtNX8x_doc.png?alt=media&amp;token=b00d2330-3620-4dd4-b410-9b357f683a73" alt="Click 'Enter company name'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">7. Fill "D"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Fill in "D"
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2Fq4DUGTt1hVYy2gg9nzvwbY_doc.png?alt=media&amp;token=3e7ae320-8a61-4c95-a787-03d7b6b06270" alt="Fill 'D'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">8. Fill "Digital Library Ltd"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Input "Digital Library Ltd" as the company name.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FqJnbkfijYV1tU6tmQU3hZH_doc.png?alt=media&amp;token=0e071007-1366-485f-9090-236d936ec621" alt="Fill 'Digital Library Ltd'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">9. Click "First Name"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Navigate to the first name field.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FfZJw15GPnWVUU6MN5Ec2Ym_doc.png?alt=media&amp;token=ab69808d-995d-4428-a3fb-de004ab0e4dc" alt="Click 'First Name'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">10. Fill "Ronald"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Fill in the text box with "Ronald"
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FgB4sPfY7vzipNb5vv2CcZu_doc.png?alt=media&amp;token=b574f411-b58c-4180-b5bf-df044c448b07" alt="Fill 'Ronald'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">11. Click "Last Name"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Navigate to the last name field.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2F4As8iS2p1SdyH4SYye7pBp_doc.png?alt=media&amp;token=7002c625-05d5-487a-9aab-ac451d278ad5" alt="Click 'Last Name'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">12. Fill "Dom"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Enter the desired text.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2Fbi4qTKQSagJmXFi5u7LsUC_doc.png?alt=media&amp;token=5590719a-f0e2-422d-a1b6-cbd6ca7e0f17" alt="Fill 'Dom'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">13. Click "Email"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Proceed to the email field.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2Fv7TF2t8QNtTorEWWumSS6o_doc.png?alt=media&amp;token=93ce7837-f121-4a99-bc23-f3416e73fe52" alt="Click 'Email'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">14. Fill "ronald@gmai"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Type "ronald@gmai" in the given field
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FoDr3FjbBBdzdjsQhMhe63Y_doc.png?alt=media&amp;token=10f15bc2-99a6-42db-97e6-677f80b5d71b" alt="Fill 'ronald@gmai'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">15. Fill "ronald@gmail.com"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Enter "ronald@gmail.com" as the email.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FrnvsP8M8VZ7qg4tr5J9wPU_doc.png?alt=media&amp;token=830572b4-4f24-4e90-bb40-96e06c0274a7" alt="Fill 'ronald@gmail.com'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">16. Go here</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Navigate to the specified location.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2Ffw4SZf9EExvzn2QVFFUgei_doc.png?alt=media&amp;token=16333321-64e1-48fb-ab88-2add16993877" alt="Go here" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">17. Fill "09012348890"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Write "09012348890" in the specified field
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2Fdd9n1aLggbKPA7qQnR9ZhU_doc.png?alt=media&amp;token=f155bf76-ec2f-4d78-8c9e-bd13506a4968" alt="Fill '09012348890'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">18. Click "Source"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Access the lead source field.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FtaMuSwy3gLcWETaAmdArC5_doc.png?alt=media&amp;token=dc8d7bc3-3c66-4a5b-80a6-8caae8e85a62" alt="Click 'Source'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">19. Click "Website"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Select the source as the website.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FueV4qykeAD8ZLaK8Tx3HBq_doc.png?alt=media&amp;token=ddb9404d-9c3c-4a95-b9ba-95a9b0ba46f7" alt="Click 'Website'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">20. Click "Designation"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Navigate to the designation field.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2F23WofYNEUvcU3tBMyXCxTU_doc.png?alt=media&amp;token=a4dda0e2-a263-4bec-b0fd-ae1c00ff87c6" alt="Click 'Designation'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">21. Fill "Line Manager"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Submit "Line Manager" in the appropriate field
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FbAAqu3BT343hpwq4GL5Qcp_doc.png?alt=media&amp;token=6e56ba27-ae32-4f20-a97e-0d96cf3c2405" alt="Fill 'Line Manager'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">22. Click "Referral"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Proceed to the referral field.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FcDdP4SDYLMakiGSfXSWdUx_doc.png?alt=media&amp;token=060899d7-b87d-45ba-b80a-2f7ca6293c4c" alt="Click 'Referral'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">23. Fill "Temiloluwa"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Input the desired text
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FfoMdaRa9jVvNVrvRQtTwcj_doc.png?alt=media&amp;token=ed856a70-3d9c-411b-88ff-3c99356e0e98" alt="Fill 'Temiloluwa'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">24. Click "Save"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Save the lead information.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2Ffb85c37UKtwVJmurfeGLUs_doc.png?alt=media&amp;token=18aa0da9-a4ca-4428-856f-6bd210f775ef" alt="Click 'Save'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">25. Click "Digital Library Ltd"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Access the lead for "Digital Library Ltd".
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2Fnujc5UUoyGPezUaFXVepZ6_doc.png?alt=media&amp;token=6e7d6cd6-d9ba-4243-99ab-c6956ab4881c" alt="Click 'Digital Library Ltd'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">26. Click "Digital Library Ltd"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Access the lead for "Digital Library Ltd".
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2F4bPFnQBQhEYYRAv2wZX9Q7_doc.png?alt=media&amp;token=7b64b1a4-65c7-4e8c-95b3-824e03eebd3c" alt="Click 'Digital Library Ltd'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">27. Click "Deals"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Navigate to the deals section.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FxuzbqcnaH3UFwMi7Gozmjx_doc.png?alt=media&amp;token=14c90940-5477-4580-8e50-caed872908fe" alt="Click 'Deals'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">28. Click "New Deal"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Start creating a new deal.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2F9Uf8iGyRKqbSM3rev7cVvi_doc.png?alt=media&amp;token=b532b51b-ceba-4e4c-baa8-2632a0e67185" alt="Click 'New Deal'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">29. Click "Deal Name"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Enter the deal name field.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FrUXbNDhMpty1phq2nM8ovQ_doc.png?alt=media&amp;token=f80c6719-9620-4cc2-8942-1b6559cfd1b7" alt="Click 'Deal Name'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">30. Fill "Recruitment"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Write your desired text.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FbTDTtSzVbEjDoWGuktuPN7_doc.png?alt=media&amp;token=25a85ee9-40e3-496a-978d-d17086e9071f" alt="Fill 'Recruitment'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">31. Click "Deal Stage"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Proceed to the deal stage field.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2F8smDiKRXyycP1ePF8JpBkt_doc.png?alt=media&amp;token=d813f6d4-8a49-4338-88a5-69797ad4e6cb" alt="Click 'Deal Stage'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">32. Click "Negotiation"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Select the negotiation stage.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2Fo16mG1LtScF7jBgXR7e6nc_doc.png?alt=media&amp;token=3bbe0278-c809-46e1-91c8-6401216e5391" alt="Click 'Negotiation'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">33. Click "Amount"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Navigate to the amount field.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2F7PFvDxxHBsi6ZnMDNMxXqY_doc.png?alt=media&amp;token=fd7e1630-4d71-4af0-98c5-bf44370139dd" alt="Click 'Amount'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">34. Fill "2500000"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Fill in "2500000"
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2Fo6V5s11KvGXTZpQChWHwyH_doc.png?alt=media&amp;token=8db139c1-4861-416a-9f39-2a545d618669" alt="Fill '2500000'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">35. Click "Currency"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Select the currency field.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FkBkR7aFKg9xniU3gLbngQj_doc.png?alt=media&amp;token=6f66bea8-ac36-4786-a218-39a2fcf00c50" alt="Click 'Currency'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">36. Click "Nigerian Naira (₦)"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Choose Nigerian Naira as the currency.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FsyDxaFDcQzavPKHkUzLyd4_doc.png?alt=media&amp;token=84e4806f-3b6e-4374-8754-a13fe0ad3965" alt="Click 'Nigerian Naira (₦)'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">37. Click "Referral Staff"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Select the referral staff field.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FpaACZ8ctvjzMNdUsUt4uHH_doc.png?alt=media&amp;token=ccf93d0a-3e17-4caf-82af-334c5582a8e3" alt="Click 'Referral Staff'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">38. Fill "temilol"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Enter "temilol" in the provided field
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FpzLxLAuBRH1L5CyDRyiqr6_doc.png?alt=media&amp;token=95266fcc-c6f6-4ac8-a32a-7677a8e7d014" alt="Fill 'temilol'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">39. Click "Temiloluwa Olasehinde (temiloluwaolasehinde@yahoo.com)"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Choose Temiloluwa Olasehinde as the referral staff.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2F4eT3R5Gf29WzgW8PoKV3Wc_doc.png?alt=media&amp;token=69aa1919-72a5-4f7b-a21a-2c40a6be9b26" alt="Click 'Temiloluwa Olasehinde (temiloluwaolasehinde@yahoo.com)'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">40. Click "Company"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Navigate to the company field.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FnrhrdqMWm8E3JwiQS4FSCg_doc.png?alt=media&amp;token=1f43bc1f-5588-4c14-9788-86dd77efdaca" alt="Click 'Company'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">41. Click "Company"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Navigate to the company field.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FxdcJLJ5MeT9zh3dLU5Zxaq_doc.png?alt=media&amp;token=f31cfdec-dca6-4451-8248-f00bc19435db" alt="Click 'Company'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">42. Click "Company"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Navigate to the company field.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2F8TJbJoJvu9tiyzdJUx8UMU_doc.png?alt=media&amp;token=befe229e-65e3-49a2-a294-2936827a964e" alt="Click 'Company'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">43. Click "Contact"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Navigate to the contact field.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2F2KKPjAsRB7SsF5YrWquRm8_doc.png?alt=media&amp;token=c7b6e72a-946d-4fdb-9363-65525199be41" alt="Click 'Contact'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">44. Fill "olus"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Fill in the text box with "olus"
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2Fq6WeK6ajmL5U7YKokLQdo4_doc.png?alt=media&amp;token=1d726965-6c65-49cf-848b-e526519dce27" alt="Fill 'olus'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">45. Click "Olusola Ogunirran (olusolao@gmail.com)"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Select Olusola Ogunirran as the contact.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FbXJ6jfw8DGFNNS1EqERuAu_doc.png?alt=media&amp;token=d4800661-fa80-4068-bed5-b58b75e2cdd3" alt="Click 'Olusola Ogunirran (olusolao@gmail.com)'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">46. Click here</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Navigate to the specified location.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2Fku6DMeLhxponaYhe9v5Lir_doc.png?alt=media&amp;token=b9c50ee7-f3ab-4c9a-945c-f8b0ef95b33e" alt="Click here" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">47. Click "5"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Select the number 5.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2Fh3rAfmB1M82s4RNqEgYw9D_doc.png?alt=media&amp;token=1e4addba-2fb2-41e6-831b-423087c164cb" alt="Click '5'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">48. Click here</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Navigate to the specified location.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2F4KkBq3trzYzzKCoishd5uG_doc.png?alt=media&amp;token=80f3cff0-1af0-462e-b9a5-e9346a7df6ee" alt="Click here" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">49. Click "27"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Select the number 27.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2Fm9qysJubbBPJ5QfqaXM5EU_doc.png?alt=media&amp;token=174ecfbe-08c6-4c21-94eb-1f098d9d80d8" alt="Click '27'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">50. Click here</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Navigate to the specified location.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2F4vZiGRTgkuxYPUagF93pCb_doc.png?alt=media&amp;token=2b62e918-ee06-4115-83e7-8ea77f88786c" alt="Click here" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">51. Fill "Recruiting a team of 10; software developers, project mangers and ui/ux designer"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Enter the desired text.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FxycxZXWpz1pYrKP3e8rLGv_doc.png?alt=media&amp;token=6a838fb7-4b72-4d9b-9c2e-e0bffced040b" alt="Fill 'Recruiting a team of 10; software developers, project mangers and ui/ux designer'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">52. Click "Save"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Save the information.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FreCdwPEnDFd9A5UEnDnrHA_doc.png?alt=media&amp;token=1081ce09-7067-4501-85ae-6a634f36f7f8" alt="Click 'Save'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">53. Click "Note Management"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Access the note management section.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FxcpdiAhwPr6Cwh6hPKr7r9_doc.png?alt=media&amp;token=7d85ab63-fa18-4b84-9a6f-651c0272af15" alt="Click 'Note Management'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">54. Click here</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Navigate to the specified location.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FuBvYPdKGQBEzjXyttPRZbR_doc.png?alt=media&amp;token=b66f5a21-b3e4-4f57-89a2-70aa0ceae745" alt="Click here" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">55. Fill "Meeting held today highlighted the addition of a team lead"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Type "Meeting held today highlighted the addition of a team lead" in the given field
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2FtQuNuRR13n18iS9CXayYME_doc.png?alt=media&amp;token=4f0404dd-5c8e-44e8-aa08-2626ff07e40f" alt="Fill 'Meeting held today highlighted the addition of a team lead'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">56. Click "Save Note"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Save the note.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2Fqmzq4KEvMXh7ctr1ccuAuM_doc.png?alt=media&amp;token=6a4808cd-c6b6-4328-9c8c-5f9d2e176515" alt="Click 'Save Note'" /> <h3 style="font-size: 1.17em; font-weight: bold; margin: 1em 0; width: 100%; word-break: break-word; max-width:100%;">57. Click "Save Note"</h3> <div style="margin-top:16px; margin-bottom:16px" >
  Save the note.
  
</div> <img width="100%" src="https://static.guidde.com/v0/qg%2Fvh8tFJJnUoYTi0aQTbA472Bg1gg1%2FiQDcvqJNo12vY1C5fjXFGT%2Fiv8XE3mM3TMtAS3Vzb6gjq_doc.png?alt=media&amp;token=93660687-af83-4b23-8c06-4746afeff881" alt="Click 'Save Note'" /> <div style="margin-top:16px; margin-bottom:16px" >
  This guide covered the process of managing leads effectively on Phillipsoutsourcing, from adding new leads with specific details like company name and email to creating deals, selecting currencies, and saving notes efficiently. Master lead management to optimize your workflow.
  
</div>
<div style="margin-right:2px"><a href="https://www.guidde.com" rel="noreferrer" target="_blank" style="text-decoration:none;color:#000000">Powered by <strong style="color:#CB0000">guidde</strong></a></div>
</div>`

export function TutorialModal({ isOpen, onClose }) {
  const [selectedTopic, setSelectedTopic] = useState(null)
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <Modal title="Dashboard Tutorial" open={isOpen} onCancel={onClose} footer={null} width={isExpanded ? "100%" : 1000} style={{ top: isExpanded ? 0 : 20 }} bodyStyle={{ height: isExpanded ? "100vh" : "auto" }}>
      <Button onClick={toggleExpand} style={{ marginBottom: 16 }}>
        {isExpanded ? <CompressOutlined /> : <ExpandAltOutlined />}
      </Button>
      <div style={{ display: "flex", height: isExpanded ? "calc(100vh - 110px)" : "calc(80vh - 110px)" }}>
        <TopicSelector onSelectTopic={setSelectedTopic} />
        {selectedTopic ? (
          <div style={{ flex: 1, display: "flex", flexDirection: "column", overflow: "scroll" }}>
            <Tabs
              defaultActiveKey="video"
              items={[
                {
                  key: "video",
                  label: "Video",
                  children: <TutorialContent topic={selectedTopic} contentType="video" />,
                },
                {
                  key: "walkthrough",
                  label: "Walkthrough",
                  children: <TutorialContent topic={selectedTopic} contentType="walkthrough" walkthroughContent={embeedContent} />,
                },
                {
                  key: "faq",
                  label: "FAQ",
                  children: <TutorialContent topic={selectedTopic} contentType="faq" />,
                },
              ]}
            />
          </div>
        ) : (
          <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center", color: "#999" }}>
            Select a topic to view its content
          </div>
        )}
      </div>
    </Modal>
  )
}

