import { ATTENDANCE_REGISTER_USER,RESET_ATTENDANCE_REGISTER,UPDATE_DATE_PER_USERID } from "../../constants/ActionTypes";

export const setAttendanceRecord = (data)=>{
return {
        type: ATTENDANCE_REGISTER_USER,
        payload: data
    };
}

export const resetAttendanceRecord = () => {
    return {
        type: RESET_ATTENDANCE_REGISTER,
    };
}

export const update_date_per_userid = (userId, newValue)=>{
    return {
        type: UPDATE_DATE_PER_USERID,
        payload:{userId,newValue}
    }
}
